<script>
import Layout from '../../layouts/auth';
import { authMethods, notificationMethods } from '@/state/helpers';
import { mapState } from 'vuex';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { validationMixin } from '@/mixins/form/validation';
import brandMixin from '@/mixins/brand.mixin';

/**
 * Login component
 */
export default {
  mixins: [validationMixin, brandMixin],
  components: {
    Layout,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loading: false,
      phoneEmail: '',
      password: '',
      remember: false,
      authErrorText: null,
      appName: process.env.VUE_APP_NAME,
    };
  },
  computed: {
    ...mapState('auth', ['status']),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...authMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn(values, actions) {
      this.loading = true;

      this.authErrorText = null;

      this.logIn({
        [this.phoneEmail.includes('@') ? 'email' : 'phone']: this.phoneEmail,
        password: this.password,
        remember: this.remember,
      })
        .then(() => {
          this.$store.dispatch('accounts/getAccounts');
          this.$store.dispatch('markets/getMarkets');
          // this.$store.dispatch('chat/initChat');

          // Redirect to the originally requested page, or to the home page
          this.$router.push(
            this.$route.query.redirectFrom || {
              name: 'home',
            }
          );
        })
        .catch(({ response }) => {
          this.authErrorText = this.handleResponseErrors(response, { values, actions });

          if (!this.authErrorText) {
            this.authErrorText = response?.data?.error?.message;
          }

          this.loading = false;
        });
    },
  },
  mounted() {
    const queryErrors = this.$route.query.errorCode;
    if (queryErrors) {
      this.authErrorText = this.$t(`errors.${queryErrors}`);
    }
  },
  metaInfo() {
    return {
      title: this.$t('Auth.Login.PageTitle'),
    };
  },
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
          <div class="card overflow-hidden">
            <div class="bg-soft bg-primary">
              <div class="row">
                <div class="col-12">
                  <div class="text-dark p-4 text-center">
                    <h5>{{ $t('Auth.Login.Title') }}</h5>
                    <p>{{ $t('Auth.Login.Subtitle', { name: appName }) }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body pt-0">
              <div>
                <div class="avatar-md profile-user-wid mb-4 mx-auto">
                  <span class="avatar-title rounded-circle bg-light">
                    <img :src="$getConfigValue('logoPath', 'VUE_APP_LOGO_URL')" alt height="42" />
                  </span>
                </div>
              </div>
              <b-alert :show="!!authErrorText" variant="danger" class="mt-3" dismissible>
                {{ authErrorText }}
              </b-alert>

              <div v-if="notification.message" :class="'alert ' + notification.type">
                {{ notification.message }}
              </div>

              <Form class="p-2" @submit="tryToLogIn">
                <Field
                  name="phone_email"
                  rules="required|phone_email"
                  :label="$t('Form.PhoneEmail')"
                  v-slot="{ errors, field }"
                >
                  <b-form-group class="mb-3" :label="$t('Form.PhoneEmail')" label-for="phone-email-field">
                    <b-form-input
                      id="phone-email-field"
                      v-model="phoneEmail"
                      type="text"
                      :class="{ 'is-invalid': errors.length }"
                      v-bind="field"
                    />

                    <ErrorMessage name="phone_email" class="invalid-feedback" />
                  </b-form-group>
                </Field>

                <Field name="password" rules="required" :label="$t('Form.Password')" v-slot="{ errors, field }">
                  <b-form-group class="mb-3" id="input-group-2" :label="$t('Form.Password')" label-for="input-2">
                    <b-form-input
                      id="input-2"
                      v-model="password"
                      type="password"
                      :placeholder="$t('Form.PasswordPlaceholder')"
                      :class="{ 'is-invalid': errors.length }"
                      v-bind="field"
                    />

                    <ErrorMessage name="password" class="invalid-feedback" />
                  </b-form-group>
                </Field>

                <b-form-checkbox
                  class="form-check me-2 mt-0"
                  id="customControlInline"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                  v-model="remember"
                >
                  {{ $t('Auth.Login.RememberMe') }}
                </b-form-checkbox>

                <div class="mt-3 d-grid">
                  <b-button type="submit" variant="dark" class="btn-block">{{ $t('Auth.Login.LogIn') }}</b-button>
                </div>
                <!-- <div class="mt-4 text-center">
                  <router-link to="/forgot-password" class="text-muted">
                    <i class="mdi mdi-lock me-1"></i> {{ $t('Auth.Login.ForgotPassword') }}
                  </router-link>
                </div> -->
              </Form>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->

          <div class="mt-5 text-center">
            <p>
              {{ $t('Auth.Login.DontHaveAccount') }}
              <router-link to="/register" class="fw-medium text-primary">{{ $t('Auth.Login.Signup') }}</router-link>
            </p>
            <div class="d-flex justify-content-center align-items-center">
              <p class="m-0">© {{ new Date().getFullYear() }}</p>
            </div>
          </div>
          <!-- end row -->
        </b-overlay>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
